import { getPersonDetailLink } from '@/contact/person/utils';
import { PersonView } from '@/contact/person/view/person-view.component';
import { useBookingActions } from '@/course-handling/booking/shared/actions.hooks';
import { CourseUnit, SetBookingStatus } from '@/graphql/generated/types';
import { Box } from '@/ui/box/box.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Grid } from '@/ui/grid/grid.component';
import { HorizontalTable } from '@/ui/horizontal-table/horizontal-table.component';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import { Loader } from '@/ui/loader/loader.component';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { getCourseUnitDates, getLocationLabel } from 'shared/course/utils';
import { Frame } from '../../../../frame';
import { Button } from '../../../../ui/button/button.component';
import { BookingStatusView } from '../../shared/booking-status-view.component';
import { BookingQuery, useBookingQuery } from './view.generated';

function getName(booking: BookingQuery['adminBooking']) {
  if (booking.__typename === 'CourseModuleBooking') {
    return (
      booking.courseModule.title ||
      booking.courseModule.courseUnit.title ||
      booking.courseModule.courseUnit.course.title
    );
  }

  if (booking.__typename === 'CourseUnitBooking') {
    return booking.courseUnit.title || booking.courseUnit.course.title;
  }
}
type BookingViewParams = {
  id?: string;
};

export const View: ComponentType<RouteComponentProps<BookingViewParams>> = (
  props,
) => {
  const bookingQuery = useBookingQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
    fetchPolicy: 'network-only',
  });

  const booking = bookingQuery.data?.adminBooking;

  const unitOrModule = useMemo(() => {
    if (!booking) {
      return undefined;
    }
    if (booking?.__typename === 'CourseModuleBooking') {
      return booking.courseModule;
    }
    return (booking as any).courseUnit as CourseUnit;
  }, [booking]);

  const { modalDelete, askForDelete, setBookingStatus } = useBookingActions(
    () => {
      navigate('/course-handling');
    },
    () => {
      bookingQuery.refetch();
    },
  );

  if (!booking || !unitOrModule) {
    return <Loader />;
  }

  return (
    <>
      <Frame.SubTitle>{getName(booking)}</Frame.SubTitle>
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/course-handling" />
        {booking.orderItem && (
          <Button
            href={`/orders/${booking.orderItem.order.id}/view`}
            label="Zur Bestellung"
          />
        )}
        {(booking.cancelledAt || booking.confirmedAt) && (
          <Button
            label="Zurücksetzen"
            onClick={() =>
              setBookingStatus(booking.id, SetBookingStatus.Unconfirmed)
            }
          />
        )}
        {(booking.cancelledAt ||
          (!booking.cancelledAt && !booking.confirmedAt)) && (
          <Button
            success
            label="Bestätigen"
            onClick={() =>
              setBookingStatus(booking.id, SetBookingStatus.Confirmed)
            }
          />
        )}
        {!booking.cancelledAt && (
          <Button
            warning
            label="Stornieren"
            onClick={() =>
              setBookingStatus(booking.id, SetBookingStatus.Cancelled)
            }
          />
        )}
        <Button error label="Löschen" onClick={() => askForDelete(booking)} />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}
        <Grid.Row>
          <Grid.Column>
            <Box title="Buchung" withMargin>
              <LabeledItem
                label="Eingegangen am"
                value={<Formatted.Date value={booking.createdAt} />}
              />
              <LabeledItem
                label="Status"
                value={<BookingStatusView booking={booking} />}
              />
              <LabeledItem
                multiline
                label="Kommentar"
                value={booking.comment}
              />
              {booking.options && (
                <LabeledItem
                  label="Weitere Angaben zur Buchung"
                  value={<HorizontalTable data={booking.options} />}
                />
              )}
            </Box>
            <Box
              title={
                <>
                  {booking.__typename === 'CourseModuleBooking'
                    ? 'Kurs-Modul'
                    : 'Kurs-Einheit'}
                  <Button
                    linkTo={
                      booking.__typename === 'CourseModuleBooking'
                        ? `/course-management/units/${booking.courseModule.courseUnit.id}/modules/${booking.courseModule.id}/edit`
                        : `/course-management/units/${
                            (booking as any).courseUnit.id
                          }/edit`
                    }
                    small
                    primary
                    label="Details"
                  />
                </>
              }
            >
              <LabeledItem label="Titel" value={getName(booking)} />
              <LabeledItem
                label="Datum"
                value={getCourseUnitDates(unitOrModule)}
              />
              <LabeledItem
                label="Standort"
                value={getLocationLabel(unitOrModule.location)}
              />
              <LabeledItem
                isBool
                label="Online"
                booleanValue={unitOrModule.online}
              />

              <LabeledItem
                isBool
                label="Fernkurs"
                booleanValue={unitOrModule.correspondenceCourse}
              />
            </Box>
          </Grid.Column>
          <Grid.Column>
            <Box
              title={
                <>
                  Teilnehmer{' '}
                  <Button
                    linkTo={getPersonDetailLink(
                      booking.customer.person,
                      'customers',
                    )}
                    label="Details"
                    small
                    primary
                  />
                </>
              }
            >
              <LabeledItem
                label="Benutzername"
                value={booking.customer.username}
              />
              <PersonView person={booking.customer.person} />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
